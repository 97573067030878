<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="작업정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && param.mdmSopId && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && param.mdmSopId && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveSopInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="안전작업표준 작업명"
                  name="sopName"
                  v-model="data.sopName">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-process
                  :editable="editable"
                  label="단위공정"
                  name="subProcessCd"
                  v-model="data.subProcessCd">
                </c-process>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="안전작업표준 관리번호"
                  name="sopNo"
                  v-model="data.sopNo">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="WORK_CLASS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workClassCd"
                  label="작업구분"
                  v-model="data.workClassCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="WORK_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workTypeCd"
                  label="작업유형"
                  v-model="data.workTypeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="WORK_KIND_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workKindCd"
                  label="작업형태"
                  v-model="data.workKindCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6 margincard-0">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :maxlength="200"
                  :editable="editable"
                  :rows="1"
                  label="작업시 안전주의사항"
                  name="saftyCaution"
                  v-model="data.saftyCaution">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6 margincard-0">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-6">
                <c-select
                  codeGroupCd="HAZARD_GRADE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="hazardGradeCd"
                  label="위험등급"
                  v-model="data.hazardGradeCd"
                ></c-select>
              </div>
              <div class="col-6">
                <c-field
                  :editable="editable"
                  label="작업자"
                  name="workerName"
                  v-model="data.workerName">
                </c-field>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-3 col-lg-3 margincard-0">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="안전보호구"
                  name="saftyShied"
                  v-model="data.saftyShied">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="적용범위"
                  name="applyRange"
                  v-model="data.applyRange">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="목적"
                  name="purpose"
                  v-model="data.purpose">
                </c-text>
              </div>
              <div class="col-12">
                <c-dept-multi label="관리부서" :parentCheckDepts="data.managementDepts" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-3 col-lg-3 margincard-0">
          <c-table
            ref="mmTable2"
            title="관련 설비"
            tableId="mmTable2"
            :columns="gridmm2.columns"
            :data="gridmm2.data"
            gridHeight="284px"
            selection="multiple"
            rowKey="mdmSopMmCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="true"
            topBorderClass="topcolor-blue"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  label="추가"
                  v-if="editable && param.mdmSopId"
                  icon="add"
                  @btnClicked="addrowMm2"
                />
                <c-btn
                  label="삭제"
                  v-if="editable && param.mdmSopId"
                  icon="remove"
                  @btnClicked="removeRowMm2"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-md-3 col-lg-3 margincard-0">
          <c-table
            ref="mmTable"
            title="관련 유해위험기계기구"
            tableId="mmTable"
            :columns="gridmm.columns"
            :data="gridmm.data"
            gridHeight="284px"
            selection="multiple"
            rowKey="mdmSopMmCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="true"
            topBorderClass="topcolor-blue"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  label="추가"
                  v-if="editable && param.mdmSopId"
                  icon="add"
                  @btnClicked="addrowMm"
                />
                <c-btn
                  label="삭제"
                  v-if="editable && param.mdmSopId"
                  icon="remove"
                  @btnClicked="removeRowMm"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-md-3 col-lg-3 margincard-0">
          <c-table
            ref="chemTable"
            title="관련 화학물질"
            tableId="chemTable"
            :columns="gridchem.columns"
            :data="gridchem.data"
            gridHeight="284px"
            selection="multiple"
            rowKey="mdmSopChemCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="true"
            topBorderClass="topcolor-blue"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  label="추가"
                  v-if="editable && param.mdmSopId"
                  icon="add"
                  @btnClicked="addrowChem"
                />
                <c-btn
                  label="삭제"
                  v-if="editable && param.mdmSopId"
                  icon="remove"
                  @btnClicked="removeRowChem"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-md-12 col-lg-12 margincard-0">
          <c-card title="개정" class="cardClassDetailForm" topClass="topcolor-orange" v-if="param.mdmSopId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="row">
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sop-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        plantName: '',
        sopName: '',
        sopNo: '',
        processName: '',
        hazardGradeName: '',
        workTypeName: '',
        workKindName: '',
        workClassName: '',
        revisionNum: '',
        regDtStr: '',
        regUserName: '',
        isRevPop: false,
      }),
    },
  },
  data() {
    return {
      saveUrl: transactionConfig.mdm.sop.job.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isRevision: false,
      insertMmUrl: '',
      deleteMmUrl: '',
      listMmUrl: '',
      insertChemUrl: '',
      deleteChemUrl: '',
      listChemUrl: '',
      data: {
        col1: '',
        col2: '',
        col3: '',
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        sopNo: '',  // 안전작업 표준 번호
        sopName: '',  // 세부공정(작업) 명
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        applyRange: '',  // 적용범위
        purpose: '',  // 목적
        hazardGradeCd: null,  // 위험등급 1 ~ 5등급
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        workClassCd: null,  // 작업구분 : Set-up, PM, BM, Part교체, 기타
        workerName: '',  // 작업자
        managementDepts: '',  // 관리부서 복수
        managementDepts2: '',  // 관리부서 복수
        saftyShied: '',  // 안전보호구
        saftyCaution: '',  // 안전주의사항
        useFlag: 'Y',  // 사용여부
        regUserId: '',
        chgUserId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        revisionFlag: 'N'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      gridmm: {
        columns: [
          {
            name: 'equipMachineCd',
            field: 'equipMachineCd',
            label: '기계기구 번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipMachineName',
            field: 'equipMachineName',
            label: '기계기구 명',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridmm2: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비 관리번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipMachineName',
            field: 'equipMachineName',
            label: '설비 명',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridchem: {
        columns: [
          {
            name: 'mdmChemMaterialId',
            field: 'mdmChemMaterialId',
            label: '화학자재코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.param.isRevPop) {
        this.editable = false;
      }
      // url setting
      this.detailUrl = selectConfig.mdm.sop.job.get.url;
      this.saveUrl = transactionConfig.mdm.sop.job.insert.url;
      this.revlistUrl = selectConfig.mdm.sop.job.list.url + '/revs';
      
      this.insertMmUrl = transactionConfig.mdm.sop.mm.insert.url;
      this.deleteMmUrl = transactionConfig.mdm.sop.mm.delete.url;
      this.listMmUrl = selectConfig.mdm.sop.mm.list.url;
      this.insertChemUrl = transactionConfig.mdm.sop.chem.insert.url;
      this.deleteChemUrl = transactionConfig.mdm.sop.chem.delete.url;
      this.listChemUrl = selectConfig.mdm.sop.chems.list.url;
      this.getSopDetail();
    },
    getSopDetail() {
      if (this.param.mdmSopId) {
        this.$http.url = this.$format(this.detailUrl, this.param.mdmSopId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.param, 'plantName', _result.data.plantName)
          this.$set(this.param, 'sopName', _result.data.sopName)
          this.$set(this.param, 'sopNo', _result.data.sopNo)
          this.$set(this.param, 'processName', _result.data.processName)
          this.$set(this.param, 'hazardGradeName', _result.data.hazardGradeName)
          this.$set(this.param, 'workTypeName', _result.data.workTypeName)
          this.$set(this.param, 'workKindName', _result.data.workKindName)
          this.$set(this.param, 'workClassName', _result.data.workClassName)
          this.$set(this.param, 'revisionNum', _result.data.revisionNum)
          this.$set(this.param, 'regDtStr', _result.data.regDtStr)
          this.$set(this.param, 'regUserName', _result.data.regUserName)
          this.isRevision = false;
          this.data.revisionFlag = 'N';
          this.getRevList(_result.data.groupId);

          this.getMms();
          this.getMms2();
          this.getChems();
        },);
      }
    },
    saveSopInfo() {
      if (this.param.mdmSopId) {
        this.saveUrl = transactionConfig.mdm.sop.job.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.sop.job.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전(작업정보/작업단계/잠재위험성별 원인결과)을 개정('+this.data.revisionNum+')하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = transactionConfig.mdm.sop.job.insert.url;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
                this.data.revisionFlag = 'Y';
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.mdmSopId = result.data.mdmSopId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST' && !this.isRevision) {
        this.$emit('insertSopInfo', result.data.mdmSopId);
      }
      this.param.mdmSopId = result.data.mdmSopId
      this.getSopDetail();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.isRevision = true;
        document.getElementById('revdiv').scrollIntoView();
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getSopDetail();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row.mdmSopId != this.data.mdmSopId) {
        if (!this.param.isRevPop) {
          this.openPop(row);
        }
      }
    },
    openPop(row) {
      this.popupOptions.title = '안전작업표준 개정이력 상세'; // 안전작업표준 개정이력 상세
      this.popupOptions.param = {
        mdmSopId: row ? row.mdmSopId : '',
        isRevPop: true,
      };
      this.popupOptions.target = () => import(`${'./sopDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '90%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getMms() {
      if (this.param.mdmSopId) {
        this.$http.url = this.listMmUrl;
        this.$http.type = "GET";
        this.$http.param = { mdmSopId: this.param.mdmSopId, equipMachineTypeCd: 'EMT0000002' };
        this.$http.request((_result) => {
          this.gridmm.data = _result.data;
        });
      }
    },
    addrowMm() {
      this.popupOptions.title = '기계/장비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
      };
      // this.popupOptions.target = () => import(`${'@/pages/sop/mim/hazardEquipment/hazardEquipmentClassPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopMm;
    },
    closePopMm(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.gridmm.data, {equipMachineCd: item.hhmHazardousMachineryId}) === -1 ) {
            saveData.push({
              mdmSopMmCd: '',
              mdmSopId: this.param.mdmSopId,
              equipMachineCd: item.hhmHazardousMachineryId,
              equipMachineTypeCd: 'EMT0000002',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
            
        this.$http.url = this.insertMmUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getMms();
          window.getApp.$emit('ALERT', {
            title: '안내 ' /* 안내 */,
            message: '저장되었습니다.' /* 저장되었습니다. */,
            type: 'success', // success / info / warning / error
          });
        });
      }
    },
    removeRowMm() {
      let selectData = this.$refs['mmTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteMmUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.getMms();
              window.getApp.$emit('ALERT', {
                title: '안내 ' /* 안내 */,
                message: '삭제되었습니다.' /* 삭제되었습니다. */,
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    getMms2() {
      if (this.param.mdmSopId) {
        this.$http.url = this.listMmUrl;
        this.$http.type = "GET";
        this.$http.param = { mdmSopId: this.param.mdmSopId, equipMachineTypeCd: 'EMT0000001' };
        this.$http.request((_result) => {
          this.gridmm2.data = _result.data;
        });
      }
    },
    addrowMm2() {
      this.popupOptions.title = '설비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopMm2;
    },
    closePopMm2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.gridmm2.data, {equipMachineCd: item.equipmentCd}) === -1 ) {
            saveData.push({
              mdmSopMmCd: '',
              mdmSopId: this.param.mdmSopId,
              equipMachineCd: item.equipmentCd,
              equipMachineTypeCd: 'EMT0000001',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
            
        this.$http.url = this.insertMmUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getMms2();
          window.getApp.$emit('ALERT', {
            title: '안내 ' /* 안내 */,
            message: '저장되었습니다.' /* 저장되었습니다. */,
            type: 'success', // success / info / warning / error
          });
        });
      }
    },
    removeRowMm2() {
      let selectData = this.$refs['mmTable2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteMmUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.getMms2();
              window.getApp.$emit('ALERT', {
                title: '안내 ' /* 안내 */,
                message: '삭제되었습니다.' /* 삭제되었습니다. */,
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    getChems() {
      if (this.param.mdmSopId) {
        this.$http.url = this.listChemUrl;
        this.$http.type = "GET";
        this.$http.param = { mdmSopId: this.param.mdmSopId };
        this.$http.request((_result) => {
          this.gridchem.data = _result.data;
        });
      }
    },
    addrowChem() {
      this.popupOptions.title = '화학자재 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      // this.popupOptions.target = () =>
      //   import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopChem;
    },
    closePopChem(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.gridchem.data, {mdmChemMaterialId: item.mdmChemMaterialId}) === -1 ) {
            saveData.push({
              mdmSopChemCd: '',
              mdmSopId: this.param.mdmSopId,
              mdmChemMaterialId: item.mdmChemMaterialId,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
            
        this.$http.url = this.insertChemUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getChems();
          window.getApp.$emit('ALERT', {
            title: '안내 ' /* 안내 */,
            message: '저장되었습니다.' /* 저장되었습니다. */,
            type: 'success', // success / info / warning / error
          });
        });
      }
    },
    removeRowChem() {
      let selectData = this.$refs['chemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteChemUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.getChems();
              window.getApp.$emit('ALERT', {
                title: '안내 ' /* 안내 */,
                message: '삭제되었습니다.' /* 삭제되었습니다. */,
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
  }
};
</script>
<style lang="sass">
.margincard-0
  margin-top: -15px
</style>